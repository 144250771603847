<template>
  <v-card>
    <v-tabs v-model="tab">
      <!-- forms -->
      <v-tooltip bottom>
        <template #activator="tooltip">
          <v-tab v-on="{ ...tooltip.on }">
            <v-icon>mdi-play-box-outline</v-icon>
          </v-tab>
        </template>
        <span>Voir les formulaires</span>
      </v-tooltip>
      <!-- responses -->
      <v-tooltip bottom>
        <template #activator="tooltip">
          <v-tab v-on="{ ...tooltip.on }">
            <v-icon>mdi-email-arrow-left-outline</v-icon>
          </v-tab>
        </template>
        <span>Voir les réponses</span>
      </v-tooltip>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- forms -->
      <v-tab-item>
        <SlideForm :profile="profile" />
      </v-tab-item>
      <!-- responses -->
      <v-tab-item>
        <Response :profile="profile" />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import SlideForm from '@/components/SlideForm/Views/SlideForm.vue'
import Response from '@/components/SlideForm/Views/Response.vue'

export default {
  props: ['profile'],
  components: { SlideForm, Response },
  data() {
    return {
      tab: 0
    }
  }
}
</script>