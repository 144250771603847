<template>
  <v-card>
    <v-card-title>Formulaires</v-card-title>

    <v-card-text class="d-flex flex-column gap-l">
      <v-card outlined v-for="form of forms" v-bind:data="form" v-bind:key="form.id" class="d-flex align-center">
        <v-tooltip bottom>
          <template #activator="tooltip">
            <span v-on="{ ...tooltip.on }">
              <!-- realtime -->
              <v-icon v-if="form.correctionType === 'realtime'" class="ma-3" color="success">
                mdi-book-check-outline
              </v-icon>

              <!-- deadline -->
              <v-icon v-else-if="form.endDate !== null && new Date().getTime() < form.endDate" class="ma-3">
                mdi-book-cancel-outline
              </v-icon>

              <!-- default -->
              <v-btn v-else-if="!form.showCorrection" icon class="ma-1" @click="form.showCorrection = true; form.save(); $forceUpdate()">
                <v-icon>mdi-book-cancel-outline</v-icon>
              </v-btn>
              <v-btn v-else icon class="ma-1" @click="form.showCorrection = false; form.save(); $forceUpdate()" color="primary">
                <v-icon>mdi-book-check-outline</v-icon>
              </v-btn>
            </span>
          </template>
          <span v-if="form.correctionType === 'realtime'">Correction en temps réel</span>
          <span v-else-if="form.endDate !== null && new Date().getTime() < form.endDate">
            Date de rendu : {{new Date(form.endDate).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris', year: 'numeric', month: 'long', day: '2-digit'})}} à {{new Date(form.endDate).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)}}
          </span>
          <span v-else-if="form.endDate !== null">Date de rendu dépassée. {{form.showCorrection !== true ? 'Afficher' : 'Masquer'}}  la correction.</span>
          <span v-else>{{form.showCorrection !== true ? 'Afficher' : 'Masquer'}} la correction.</span>
        </v-tooltip>

        <v-card-text class="d-flex justify-space-between py-3 px-0">
          <p class="mb-0">{{ form.name }}</p>
          <p class="mb-0">{{ form.slides.length }} silde{{ form.slides.length > 1 ? 's' : '' }} | {{ getNbQuestion(form) }} question{{ getNbQuestion(form) > 1 ? 's' : '' }}</p>
        </v-card-text>

        <v-card-actions class="pb-0">
          <v-tooltip bottom>
            <template #activator="btnCopie">
              <v-btn icon @click="duplicate(form)" v-on="{ ...btnCopie.on }">
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </template>
            <span>Dupliquer</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="btnUpdate">
              <v-btn icon color="primary" @click="$refs.saveFormModal.open(form)" v-on="{ ...btnUpdate.on }">
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </template>
            <span>Modifier</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="btnDelete">
              <v-btn icon color="error" @click="onDeleteClick(form)" v-on="{ ...btnDelete.on }">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </template>
            <span>Supprimer</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn fab color="primary" @click="$refs.saveFormModal.open()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-card-actions>

    <SaveFormModal ref="saveFormModal" :profile="profile" />
  </v-card>
</template>

<script>
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from 'firebase/storage'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import SlideForm from '@/classes/SlideForm'
import FormResponse from '@/classes/FormResponse'
import Note from '@/classes/Note'
import SaveFormModal from '@/components/SlideForm/SaveFormModal.vue'

export default {
  props: ['profile'],
  components: { SaveFormModal },
  data() {
    return {
      storage: getStorage(),
      forms: [],
      unsub: []
    }
  },
  async created() {
    this.unsub.push(SlideForm.listenByRoom(this.profile.currentRoom, data => {
      for (const form of data) {
        for (const slide of form.slides) {
          if (slide.content.media) {
            const fileRef = ref(this.storage, slide.content.media.path)
            getDownloadURL(fileRef).then(response => {
              slide.content.media.ref = response
            })
          } else if (slide.layout === 'question' && slide.content.question && slide.content.question.type === 'selectImage') {
            for (const choice of slide.content.question.choices) {
              if (choice) {
                const fileRef = ref(this.storage, choice.path)
                getDownloadURL(fileRef).then(response => {
                  choice.ref = response
                })
              }
            }
          }
        }
      }
      this.forms = data
    }))
  },
  destroyed() {
    this.unsub.forEach(unsub => {
      if (typeof unsub === 'function')
        unsub()
    })
  },
  methods: {
    onDeleteClick(form) {
      Swal.fire({
        title: 'Etes-vous sûr ?',
        text: 'Supprimé un formulaire supprimera toutes les réponses associées.',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler'
      }).then(result => {
        if (result.isConfirmed) this.deleteForm(form)
      })
    },

    deleteForm(form) {
      if (form && form.id) {

        // medias
        if (form.slides) {
          const files = []

          for (const slide of form.slides) {
            if (slide.content.media) {
              files.push(slide.content.media.path)
            }
            if (slide.layout === 'question' && slide.content.question && slide.content.question.type === 'selectImage') {
              for (const choice of slide.content.question.choices) {
                if (choice) {
                  files.push(choice.path)
                }
              }
            }
          }
          
          for (const filePath of files) {
            const fileRef = ref(this.storage, filePath)
            deleteObject(fileRef)
          }
        }

        // formResponses
        FormResponse.getByForm(form.id).then(responses => {
          for (const response of responses) {
            response.delete()
          }
        })
        // update note
        Note.getByForm(form.id).then(notes => {
          for (const note of notes) {
            note.form = null
            note.slideFormNote = false
            note.save()
          }
        })

        form.delete()
        this.$forceUpdate()
      }
    },

    getNbQuestion(form) {
      let nbQuestion = 0

      for (const slide of form.slides.filter(x=>x.layout==='question')) {
        if (slide.content.questions) {
          nbQuestion += slide.content.questions.length
        } else if (slide.content.question) {
          nbQuestion ++
        }
      }

      return nbQuestion
    },

    duplicate(old_form) {
      Swal.fire({
        title: 'Etes-vous sûr ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler',
      }).then(async result => {
        if (result.isConfirmed) {
          const old_id = old_form.id
          const new_id = await SlideForm.getANewId()

          const new_form = Object.assign(new SlideForm(), JSON.parse(
            JSON.stringify(old_form).replaceAll(old_id, new_id)
          ))
          new_form.name = `${old_form.name} (copie)`

          // media
          const old_paths = []
          for (const slide of old_form.slides) {
            if (slide.content.media) {
              old_paths.push(slide.content.media.path)
            }
            if (slide.layout === 'question' && slide.content.question && slide.content.question.type === 'selectImage') {
              for (const choice of slide.content.question.choices) {
                if (choice) {
                  old_paths.push(choice.path)
                }
              }
            }
          }

          const new_paths = JSON.parse(
            JSON.stringify(old_paths).replaceAll(old_id, new_id)
          )

          await Promise.all(old_paths.map(async (old_path, index) => {
            console.log("test")
            const old_fileRef = ref(this.storage, old_path)
            const new_fileRef = ref(this.storage, new_paths[index])

            const  url = await getDownloadURL(old_fileRef)
            const xhr = new XMLHttpRequest()
            xhr.responseType = 'blob'
            xhr.onload = async () => {
              xhr.response.name = new_fileRef.name
              await uploadBytes(new_fileRef, xhr.response)
            }
            await xhr.open('GET', url)
            await xhr.send()
          }))
          new_form.isNewForm = true
          new_form.save()
        }
      })
    }
  }
}
</script>

<style scoped>
.correction-type {
  margin: 0 16px;
  width: 24px;
  aspect-ratio: 1/1;
  height: 24px;
  border: solid 1px var(--v-text-base);
  border-radius: 12px!important;
}
</style>