<template>
  <v-dialog v-model="isOpen" persistent max-width="750px" v-if="form">
    <v-card>
      <v-card-title>Paramètre du formulaire</v-card-title>

      <v-card-text class="py-3 d-flex flex-column gap-xxl">
        <div class="d-flex align-center gap-xs">
          <h4 class="mb-0 nowrap">Correction :</h4>
          <v-select :items="correction" item-text="text" item-value="value" v-model="form.correctionType" solo hide-details />
        </div>
        <div v-if="form.correctionType === 'standard'">
          <div class="d-flex align-center gap-xs">
            <h4 class="mb-0">Réponses modifiables :</h4>
            <v-checkbox :label="form.editableResponses ? 'Oui' : 'Non'" hide-details class="pt-0 mt-0" v-model="form.editableResponses" />
          </div>
          <div class="d-flex align-center gap-xs" v-if="form.editableResponses">
            <p class="mb-0">Jusqu'à :</p>
            <v-text-field type="date" hide-details class="pt-0 mt-0" v-model="endDate" />
            <v-text-field type="time" hide-details class="pt-0 mt-0" v-model="endTime" />
          </div>
        </div>

        <div class="d-flex align-center gap-xs">
          <h4>Note final convertie sur :</h4>
          <v-text-field type="number" hide-details class="pt-0 mt-0" min="1" v-model="form.pointsConvertion" placeholder="optionnel" />
        </div>
      </v-card-text>

      <v-card-actions class="justify-end pt-3">
        <v-btn color="error" @click="close">Annuler</v-btn>
        <v-btn color="primary" @click="save">Valider</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  data() {
    return {
      isOpen: false,
      form: null,
      referenceForm: null,
      endDate: null,
      endTime: null,

      correction: [
        {
          text: 'Correction standard',
          value: 'standard'
        },
        {
          text: 'Correction en temps réel',
          value: 'realtime'
        }
      ]
    }
  },
  watch: {
    form: {
      handler(newValue) {
        if (newValue && newValue.correctionType !== 'standard') {
          this.form.editableResponses = null
          this.form.endDate = null
        }
      },
      deep: true
    }
  },
  methods: {
    open(form) {
      this.isOpen = true
      this.referenceForm = form
      this.form = JSON.parse(JSON.stringify(form))

      const displayedDate = this.form.endDate ? this.form.endDate : new Date().getTime()
      this.endTime = new Date(displayedDate).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 5)
      if (this.endTime === '00:00') {
        this.endDate = new Date(displayedDate+(1000*60*60*24)).toISOString().slice(0, 10)
      } else {
        this.endDate = new Date(displayedDate).toISOString().slice(0, 10)
      }
    },
    close() {
      this.form = null
      this.isOpen = false
    },
    save() {
      if (this.form.editableResponses && (!this.endDate || !this.endTime)) {
        Swal.fire({
          title: 'Erreur',
          text: 'Date de rendu invalide',
          icon: 'error',
          confirmButtonText: 'Ok',
        })
        return
      } else {
        if (!this.form.pointsConvertion || this.form.pointsConvertion.trim() === '') this.form.pointsConvertion = null

        this.referenceForm.correctionType = this.form.correctionType
        this.referenceForm.editableResponses = this.form.editableResponses
        this.referenceForm.pointsConvertion = this.form.pointsConvertion
        this.referenceForm.endDate = !this.form.editableResponses ? null : new Date(this.endDate + " " + this.endTime).getTime()
        if (this.referenceForm.endDate !== null && this.referenceForm.endDate < new Date().getTime()) this.referenceForm.showCorrection = false
        this.close(new Date().getTime())
      }
    }
  }
}
</script>

<style scoped>
.nowrap {
  customWhite-space: nowrap;
}
</style>