<template>
  <v-card>
    <v-card-title>Réponses</v-card-title>

    <v-card-text>
      <v-expansion-panels>
        <v-expansion-panel v-for="form of forms" :key="form.id">
          <v-expansion-panel-header class="py-6">
            {{ form.name }}
            <v-spacer />
            {{ responses[form.id].length + '/' + nbUsers }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-card v-for="response of responses[form.id]" :key="response.id"
              @click="onResponseClick(response)"
              outlined class="my-3 pa-3 d-flex align-center gap-s"
            >
              <UserCard v-if="users[response.sender]" :profile="users[response.sender]" />
              <v-spacer />
              <span class="d-flex flex-column align-end">
                <span>{{ new Date(response.lastUpdateDate).toLocaleDateString('fr-FR', { timeZone: 'Europe/Paris' }) }}</span>
                <span class="text-caption">{{ new Date(response.lastUpdateDate).toLocaleTimeString('fr-FR', { timeZone: 'Europe/Paris' }) }}</span>
              </span>
              <v-btn icon color="error" @click.prevent.stop="onDeleteClick(response)">
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </v-card>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    
    <UserForm ref="formModal" :profile="profile" :form="selectedForm" :response="selectedResponse"
      :nextAndPreviousUser="true" :userName="selectedUser" @previous="previousUser" @next="nextUser"
    />
  </v-card>
</template>

<script>
import UserCard from '@/components/UserCard'
import UserForm from '@/components/SlideForm/UserForm.vue'

import FormResponse from '@/classes/FormResponse'
import SlideForm from '@/classes/SlideForm'
import Profile from '@/classes/Profile'

import Swal from 'sweetalert2/dist/sweetalert2.js'

export default {
  props: ['profile'],
  components: { UserCard, UserForm },
  data() {
    return {
      responses: {},
      users: {},
      nbUsers: 0,
      forms: [],
      selectedForm: null,
      selectedResponse: null,
      selectedUser: '',
      unsub: []
    }
  },
  created() {
    this.unsub.push(Profile.listenByRoomAndByRole(this.profile.currentRoom, 'User', profiles => {
      this.users = {}
      for (const profile of profiles) {
        this.users[profile.id] = profile
      }
      this.nbUsers = profiles.length
    }))
    this.unsub.push(FormResponse.listenByRoom(this.profile.currentRoom, responses => {
      this.responses = {}
      this.forms = []

      for (const res of responses) {
        if (this.responses[res.form] === undefined) {
          this.responses[res.form] = []
          SlideForm.getById(res.form).then(form => {
            if (form) this.forms.push(form)
          })
        }
        this.responses[res.form].push(res)
        this.responses[res.form].sort((a,b)=>{
          if (this.users[a.sender].lastname === this.users[b.sender].lastname) {
            return this.users[a.sender].firstname > this.users[b.sender].firstname ? 1 : -1
          } else {
            return this.users[a.sender].lastname > this.users[b.sender].lastname ? 1 : -1
          }
        })
      }
    }))
  },
  destroyed() {
    this.unsub.forEach(unsub => {
      if (typeof unsub === 'function')
        unsub()
    })
  },
  methods: {
    onDeleteClick(response) {
      Swal.fire({
        title: "Etes-vous sûr ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Annuler",
      }).then(async result => {
        if (result.isConfirmed)
          await response.delete()
          this.$forceUpdate()
      })
    },
    onResponseClick(response) {
      this.selectedForm = this.forms.find(form => form.id === response.form)
      this.selectedResponse = response

      const user = this.users[this.selectedResponse.sender]
      this.selectedUser = user.firstname + ' ' + user.lastname

      setTimeout(() => {
        this.$refs.formModal.open()
      }, 0)
    },
    previousUser() {
      const responses = this.responses[this.selectedForm.id]
      const actualIndex = responses.findIndex(response => response.id === this.selectedResponse.id)
      const new_index = (actualIndex-1 < 0) ? responses.length-1 : actualIndex-1
      this.selectedResponse = responses[new_index]

      const user = this.users[this.selectedResponse.sender]
      this.selectedUser = user.firstname + ' ' + user.lastname

      this.$refs.formModal.close()
      setTimeout(() => {
        this.$refs.formModal.open()
      }, 0)
    },
    nextUser() {
      const responses = this.responses[this.selectedForm.id]
      const actualIndex = responses.findIndex(response => response.id === this.selectedResponse.id)
      const new_index = (actualIndex+1 > responses.length-1) ? 0 : actualIndex+1
      this.selectedResponse = responses[new_index]

      const user = this.users[this.selectedResponse.sender]
      this.selectedUser = user.firstname + ' ' + user.lastname

      this.$refs.formModal.close()
      setTimeout(() => {
        this.$refs.formModal.open()
      }, 0)
    }
  }
}
</script>